<template>
  <div>
    <div class="home-top">
      <a href="/"><img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/logo.png" alt="" /></a>
      <div class="home-top-right">
        <ul>
          <li :class="{ active: checked == '/' }"><a href="/">首页</a></li>
          <li :class="{ active: checked == '/about' }">
            <a href="/about">关于我们</a>
          </li>
          <li class="navc" :class="{ active: checked == '/serve' }">
            <a href="/serve">凯洋服务</a>
            <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/gengduo.png" alt="" />
            <div class="selected">
              <ul class="selected-li">
                <li>
                  <a href="/fdetail?cid=0" @click="tabclick(0)">国际海运</a>
                </li>
                <li>
                  <a href="/fdetail?cid=1" @click="tabclick(1)">国际空运</a>
                </li>
                <li>
                  <a href="/fdetail?cid=2" @click="tabclick(2)">东南亚陆运</a>
                </li>
                <li>
                  <a href="/fdetail?cid=3" @click="tabclick(3)">国际电商</a>
                </li>
                <li>
                  <a href="/fdetail?cid=4" @click="tabclick(4)">仓储服务</a>
                </li>
              </ul>
            </div>
          </li>
          <li :class="{ active: checked == '/contact' }">
            <a href="/contact">联系我们</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: "",
    };
  },
  methods: {
    tabclick(e) {
      this.$emit("selectchecked", e);
    },
  },
  created() {
    this.checked = this.$route.path;
  },
};
</script>

<style lang="scss" scoped>
// 顶部
.home-top {
  width: 1200px;
  height: 55px;
  margin: 0 auto;
  display: flex;
  padding-top: 27px;
  justify-content: space-between;
  > a > img {
    width: 244px;
    height: 55px;
  }
  // 顶部导航
  &-right {
    height: 55px;
    line-height: 55px;
    width: 401px;
    > ul {
      display: flex;
      justify-content: space-between;
      > li {
        color: white;
        font-size: 18px;
        font-weight: Medium;
        > a {
          color: white;
        }
      }
      > li:hover {
        font-weight: bold;
        border-bottom: 2px solid white;
      }
      // 下拉菜单
      > .navc {
        position: relative;
        &:hover .selected {
          display: block;
        }
        > .selected {
          display: none;
          position: absolute;
          top: 55px;
          left: -16px;
          width: 130px;
          // height: 200px;
          background-color: #444444;
          text-align: center;
          color: white;
          .selected-li > li {
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            > a {
              color: white;
            }
          }
           .selected-li > li:hover{
              opacity: 0.7;
           }
        }
      }
    }
  }
}
// 顶部选中
.active {
  font-weight: bold;
  border-bottom: 2px solid white;
}
</style>