<template>
  <div>
    <!-- 顶部 -->
    <div class="home">
      <Top />
      <!-- 标题 -->
      <div class="home-title">
        <h1 class="titleh1">广东凯洋国际物流</h1>
        <h3 class="titleh1">我们不限于客户的满意，我们力求做到杰出</h3>
      </div>
      <div class="home-xia">
        <a href="javascript:;" @click="goAnchor('#serve')">
          <img class="hoverd" src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/circle.png" alt="" />
        </a>
      </div>
    </div>
    <!-- 简介 -->
    <div class="content" id="serve">
      <div class="content-title">
        <!-- <div class="content-title-n">ABOUT ZHUANG BA</div> -->
        <div class="content-title-b">
          <div class="xian"></div>
          <div class="titatle">企业简介</div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="content-cont">
          <img class="content-cont-l" src="../assets/image/white.png" style="width:290px;height:238px;background-color:white;" alt="" />
        
        <div class="content-cont-r">
         广东凯洋国际物流供应链有限公司由东莞市工商、交通运输部门批准成立，东莞市物流行业协会会员单位。是一家专业从事国际海运、空运、东南亚陆运及仓储物流于一体的综合性国际货运代理公司，是经国家外经贸部批准成立的一级货运代理企业，同时具有交通部认证的无船承运人资格，本公司拥有一批专业的物流管理人才和专业的运输队伍，以严格、科学的管理手段，现代化的物流设施，为客户提供最优质的服务。...... <br>
          <a href="/about">了解更多</a>
        </div>
      </div>
    </div>
    <!-- 服务 -->
    <div class="serve">
      <div class="content-title">
        <!-- <div class="content-title-n">ABOUT ZHUANG BA</div> -->
        <div class="content-title-b">
          <div class="xian"></div>
          <div class="titatle">凯洋服务</div>
          <div class="xian"></div>
        </div>
      </div>
      <div class="serve-swiper" style="margin-top: 25px; min-width: 1200px">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide style="width: 100%">
            <a href="/fdetail?cid=0">
              <div class="fatherswiper">
                              <div class="afters"></div>

                <h1 class="swiper-title" :style="'font-size:' + tsize + 'px'">
                  国际海运
                </h1>
                <div class="swiper-text" :style="'font-size:' + xsize + 'px'">
                  提供订舱、租船、报关、堆场装箱、产地装箱、商检、动植物检、危险品申报、仓储及地面运输服务。
                </div>
                <img class="bolarder" src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/S2.png" alt="" />
              </div>
            </a>
          </swiper-slide>
          <swiper-slide style="width: 100%">
            <a href="/fdetail?cid=1">
                          <div class="fatherswiper">
              <div class="afters"></div>

              <h1 class="swiper-title" :style="'font-size:' + tsize + 'px'">
                国际空运
              </h1>
              <div class="swiper-text" :style="'font-size:' + xsize + 'px'">
                与多家航空公司深度合作，主营航线为东南亚、日本、中东、红海、美加、欧洲、非洲。
              </div>
              <img class="bolarder" src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/s3.png" alt=""
            />
                          </div>

            </a>
          </swiper-slide>
          <swiper-slide style="width: 100%">
            <a href="/fdetail?cid=2">
            <div class="fatherswiper">
                            <div class="afters"></div>

              <h1 class="swiper-title" :style="'font-size:' + tsize + 'px'">
                东南亚陆运
              </h1>
              <div class="swiper-text" :style="'font-size:' + xsize + 'px'">
                覆盖东南亚十几个国家及地区，零担整车专线直达，减少中间转包环节，为企业降低物流成本。
              </div>
              <img class="bolarder" src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/S4.png" alt=""
            />
            </div>
            </a>
          </swiper-slide>
          <swiper-slide style="width: 100%">
            <a href="/fdetail?cid=3">
            <div class="fatherswiper">
              <div class="afters"></div>
              <h1 class="swiper-title" :style="'font-size:' + tsize + 'px'">
                国际电商
              </h1>
              <div class="swiper-text" :style="'font-size:' + xsize + 'px'">
                一站式系统为客户提供足不出户就能买遍天下货的优质可靠服务。
              </div>
              <img class="bolarder" src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/f4.png" alt=""
            />
            </div></a>
          </swiper-slide>
          <swiper-slide style="width: 100%">
            <a href="/fdetail?cid=4">
            <div class="fatherswiper">
                            <div class="afters"></div>

              <h1 class="swiper-title" :style="'font-size:' + tsize + 'px'">
                仓储服务
              </h1>
              <div class="swiper-text" :style="'font-size:' + xsize + 'px'">
                自营仓库分布于东莞、广州、深圳、越南、缅甸、马来西亚、美国、日本和其他国家深度合作仓库。
              </div>
              <img class="bolarder" src="../assets/image/f5.png" alt=""
            />
            </div></a>
          </swiper-slide>
        </swiper>
        <!-- <a class="looka" href="/serve">了解更多</a> -->
      </div>
    </div>
    <!-- 优势 -->
    <div class="avatage">
      <!-- title -->
      <div class="avatage-content">
        <div class="content-title">
          <!-- <div class="content-title-n">ABOUT ZHUANG BA</div> -->
          <div class="content-title-b">
            <div class="xian"></div>
            <div class="titatle">凯洋优势</div>
            <div class="xian"></div>
          </div>
        </div>
        <p class="avatage-content-t">
          我司在全球有非常全面的代理网络，能为客户提供进出口门到门运输服务。我司一直秉承着“我们不限于客户的满意，我们力求做到杰出”的理念，致力于发展成为供应链一体化的供应商。
        </p>
        <div class="avatage-content-li">
          <ul>
            <li><img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/feiji.png" alt="" /></li>
            <li>
              <div class="libianji">
                <h3>丰富的货运经验</h3>
                <h5>RICH TRANSPORTATION EXPERIENCE</h5>
                <p>
                  我司专业从事国际运输事务。与各大航司、船司紧密合作，舱位稳定，加上优质的通关能力，高效的运输效率得以保证。
                </p>
              </div>
            </li>
            <li>
              <div class="libianji" style="margin-left: 0; margin-right: 30px">
                <h3>完善的储运系统</h3>
                <h5>PERFECT STORAGE TRANSPORTATION SYSTEM</h5>
                <p>
                  凯洋国际物流专注于国际海运、空运、陆运仓储及运输服务。于东莞、广州、深圳设有直管仓库，面积有几千平方米，与十几家知名公司建立了密切的合作关系。
                </p>
              </div>
            </li>
            <li><img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/xiangzi.png" alt="" /></li>
            <li><img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/y3.png" alt="" /></li>
            <li>
              <div class="libianji">
                <h3>齐全的售后服务</h3>
                <h5>COMPLETE AFTER-SALES SERVICE</h5>
                <p>
                  各部门各尽其责，用心处理个工作环节，同事紧密协作，围绕“客户至上，服务第一”的宗旨开展工作，致力为客户提供专业的专线物流服务。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <Partner />
    <Footer />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Footer from "../components/footer.vue";
import Top from "../components/topnav.vue";
import Partner from "../components/partner.vue";

export default {
  data() {
    return {
      freeMode: true,
      screenWidth: "",
      swiperOptions: {
        tsize: 48, //字体动态大小,
        xsize: 18,
        // 自动播放
        autoplay: true,
        // 环路播放
        loop: true,
        // 切换效果
        // effect: "cube",
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        slidesPerView: 3.5,
        spaceBetween: 30,
        // 前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    Footer,
    Top,
    Partner,
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
    // 平滑滚动
    goAnchor(e) {
      this.$el.querySelector(e).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
  mounted() {
    const that = this;
    // 页面宽度变化
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    // 监听页面尺寸变化计算文字像素
    screenWidth(val) {
      var cwith = val;
      this.tsize = cwith / 40;
      if (this.tsize < 30) return (this.tsize = 30);
      document.querySelector(".swiper-title").style.fontSize =
        this.tsize + "px";
      this.xsize = cwith / 106.6;
      if (this.xsize < 11.5) return (this.xsize = 11.5);
      document.querySelector(".swiper-text").style.fontSize = this.xsize + "px";
    },
  },
  created() {
    var cwith = document.body.clientWidth;
    this.tsize = cwith / 40;
    if (this.tsize < 30) return (this.tsize = 30);
    if (this.xsize < 11.5) return (this.xsize = 11.5);
    this.xsize = cwith / 106.6;
  },
};
</script>

<style lang="scss">
$cmg: 0 auto;
.home {
  width: 100%;
  height: 100vh;
  background-image: url("https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/intro5.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  // 中间title
  &-title {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-51%);
    color: white;
    text-align: center;
    > h1 {
      font-size: 80px;
    }
    > h3 {
      font-size: 30px;
      font-weight: 300;
    }
  }
  &-xia {
    position: absolute;
    bottom: 8%;
    text-align: center;
    width: 100%;
  }
}
// 简介
.content {
  width: 1200px;
  margin: $cmg;
  margin-top: 70px;
  text-align: center;
  // 企业简介标题
  &-title {
    &-n {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #444444;
      text-align: center;
    }
    &-b {
      margin: 13px auto;
      width: 302px;
      display: flex;
      justify-content: space-between;
      > .xian {
        height: 15px;
        width: 73px;
        border-bottom: 1px dashed #333333;
      }
      > .titatle {
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  // 企业简介
  &-cont {
    width: 1200px;
    height: 312px;
    background-color: #172e53;
    margin-top: 80px;
    position: relative;
    &-l {
      position: absolute;
      // top: -89px;
      top: 50%;
      transform: translateY(-51%);
      left: 64px;
    }
    &-r {
      position: absolute;
      top: 53px;
      left: 477px;
      width: 670px;
      text-align: left;
      text-indent: 30px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      line-height: 27px;
      > a {
        display: inline-block;
        width: 150px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        text-indent: 0;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #ffffff;
        margin-top: 35px;
        background-color: #f29500;
      }
    }
  }
}
// 服务
.serve {
  margin-top: 80px;
  text-align: center;
  width: 100%;
  &-swiper {
    min-height: 500px;
    > a {
      margin: 48px auto;
    }
  }
}
// 优势
.avatage {
  width: 100%;
  background-color: #f5f5f5;
  margin-top: 18px;
  padding-top: 20px;
  margin-bottom: 100px;
  &-content {
    width: 1200px;
    margin: $cmg;
    &-t {
      margin-top: 26px;
      line-height: 25px;
      text-indent: 30px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    &-li {
      margin-top: 45px;
      width: 100%;
      > ul {
        overflow: hidden;
        > li {
          width: 50%;
          height: 380px;
          float: left;
          > img {
            width: 100%;
            height: 100%;
          }
          .libianji {
            margin-top: 99px;
            margin-left: 30px;
            > h3 {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
            }
            > h5 {
              font-weight: 300;
              font-size: 16px;
              margin-top: 11px;
            }
            > p {
              margin-top: 20px;
              font-size: 18px;
              line-height: 25px;
              text-indent: 30px;
            }
          }
        }
      }
    }
  }
}

// swiper
.fatherswiper {
  width: 100%;
  position: relative;
  font-size: 18px;
  overflow: hidden;
}
.fatherswiper:hover .bolarder {
  transform: scale(1.1);
}
.bolarder {
  width: 100%;
  transition: transform 2s;
  height: 100%;
}
.afters{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: black;
  opacity: 0.6;
}
.swiper-title {
  position: absolute;
  top: 31%;
  left: 50%;
    z-index: 99;

  transform: translateX(-51%);
  font-size: 48rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #ffffff;
}
.swiper-text {
  position: absolute;
  top: 59%;
  left: 50%;
  z-index: 99;
  width: 61%;
  transform: translateX(-51%);
  font-size: 18rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.titleh1 {
  -webkit-animation-name: fadein;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
}

@-webkit-keyframes fadeinc {
  0% {
    bottom: 8%;
  }
  50% {
    bottom: 12%;
  }
  100% {
    bottom: 8%;
  }
}
.home-xia:hover {
  -webkit-animation-name: fadeinc;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: 5;
}
</style>