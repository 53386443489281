<template>
 <div class="hezuo">
      <div class="content-title">
        <div class="content-title-n">PARTNERS</div>
        <div class="content-title-b">
          <div class="xian"></div>
          <div class="titatle">合作伙伴</div>
          <div class="xian"></div>
        </div>
      </div>
         <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/hezuo.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
// 合作
.hezuo {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 124px;
  >img{
    width: 100%;
    margin-top: 29px;
  }
}
</style>