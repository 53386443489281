<template>
  <div class="footer">
    <div class="footerd">
      <img class="footerd-img" src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/logo.png" alt="" />
      <div class="footerd-center">
        <h3>广东凯洋国际物流供应链有限公司</h3>
         <h3 style="font-size:16px;font-weight:300;letter-spacing: 0px;margin-top:10px">Guangdong Kayon International Logistics Supply Chain Co.,Ltd</h3>
        <p style="margin-top: 26px;">电话: <span style="opacity:0">f</span>0769-81731186</p>
        <p>手机：13790269392 <span style="width:10px;height:5px;display:inline-block"></span> QQ：460631348</p>
        <p>邮箱：akon@gdkayon.com </p>
        <p>地址：广东省东莞市高埗镇北王路高埗段86号3号楼1039室</p>
        <p>Room 1039, Building 3, 86 North Wang Road, Gaobu Town, Dongguan City, Guangdong Province</p>
        <p style="color:#bbb;font-size:14px;margin-top:30px">广东凯洋国际物流供应链有限公司    备案号：<a href="http://beian.miit.gov.cn/" style="color:#bbb;" target="_blank"> 粤ICP备2021032197号</a></p>
      </div>
      <div class="footerd-ms">
        <div class="wm">联系我们</div>
        <img src="https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/weixinercode.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 338px;
  background-color: #00263d;
}
.footerd {
  width: 1200px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  &-img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-51%);
    width: 196px;
    height: 44px;
  }
  &-center {
    padding-top: 72px;
    margin-left: 269px;
    > h3 {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #fffefe;
      letter-spacing: 7px;
    }
    > p {
      font-size: 16px;
      line-height: 27px;
      color: #fffefe;
    }
  }
  &-ms {
    position: absolute;
    right: 0;
    top: 77px;
    width: 120px;
    > .wm {
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #fffefe;
    }
    > img {
      width: 100%;
      margin-top: 22px;
    }
  }
}
</style>